import da from "./locales/da.json"
import de from "./locales/de.json"
import en from "./locales/en.json"
export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en_US",
  messages: {
    da_DK: da,
    en_US: en,
    de_DE: de,
  },
}))
